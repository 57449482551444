<template>
  <div class="facilityBox">
    <!-- 主体部分 -->
    <div class="bodyArea">
      <div class="title">运单信息进度</div>
      <!-- 步骤条 -->
      <Step class="step" :activeList="[1, 1, 0, 0]"></Step>
      <!-- 合计 -->
      <div class="settleBox">
        <el-row>
          <el-col :span="5">
            开票申请时间：<strong>{{ total.settlementDate }}</strong></el-col>
          <el-col :span="5">运单数量(条)：<strong>{{ waybillList.length }}</strong></el-col>
          <el-col :span="5">运费(元)：<strong>￥{{ total.totalFreight }}</strong></el-col>
          <el-col :span="9">服务费/税费(元)：<strong>￥{{ total.totalTaxes }}</strong></el-col>
        </el-row>
        <el-row>
          <el-col :span="5">{{
            getWaybillType.sceneId == 7 ? "工单" : "运单"
          }}总金额(元)：<strong>￥{{ total.totalMoney }}</strong>
          </el-col>
          <el-col :span="5">货主单位：<strong>{{
            total.bossEnterpriseFullName
          }}</strong></el-col>
          <el-col :span="5">货主税号：<strong>{{
            total.bossEnterpriseCreditCode
          }}</strong></el-col>
          <el-col :span="9">货主地址：<strong>{{
            total.bossEnterpriseAddress
          }}</strong></el-col>
        </el-row>
        <el-row>
          <el-col :span="5">货主电话：<strong>{{
            total.bossLegalPersonlPhone
          }}</strong></el-col>
          <el-col :span="5">货主账号：<strong>{{ total.bossBankerNumber }}</strong></el-col>
          <!-- <el-col :span="5" v-if="invoiceForm.invoicingType == 1">专票普票：<strong>{{ invoiceKind }}</strong></el-col>
          <el-col :span="5" v-if="invoiceForm.invoicingType == 2">专票普票：<strong>运费-{{ invoiceKind }}
              技术服务费-{{ invoiceKindTec }}</strong></el-col> -->
          <el-col :span="5">专票普票：<strong>运费-{{ invoiceKind }} 技术服务费-{{ invoiceKindTec }}</strong></el-col>
          <el-col :span="9">货主开户行：<strong>{{ total.bossBankerName }}</strong></el-col>
        </el-row>
        <el-row>
          <el-col :span="5">支付方式：<strong></strong></el-col>
        </el-row>
        <!-- <el-row>
          <el-col :span="24">开票备注：{{ total.KPRemark }}</el-col>
        </el-row> -->
      </div>
      <!-- 表单区域 -->
      <el-form class="invoiceForm" :model="invoiceForm" :rules="invoiceRule" ref="invoiceForm" label-width="120px">
        <!-- 选择地区 -->
        <!-- <el-form-item label="选择地区" prop="address.area" label-width="100px">
          <el-cascader style="width:554px" v-model="invoiceForm.address.area" :props="invoiceForm.address" :disabled="invoiceForm.address.disabled" clearable></el-cascader>
        </el-form-item> -->
        <!-- 已签约服务商 -->
        <el-form-item label="已签约服务商" prop="supplier.value" label-width="100px">
          <el-select v-model="invoiceForm.supplier.value" clearable placeholder="已签约服务商"
            :disabled="invoiceForm.supplier.disabled">
            <el-option v-for="(item, index) in invoiceForm.supplier.list" :key="index" :label="item.name"
              :value="item.name" :disabled="item.Flag == 1 || item.Disabled">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 服务商 -->
        <!-- <el-form-item label="未签约服务商" prop="enterInfo.value" label-width="100px">
          <el-select v-model="invoiceForm.enterInfo.value" clearable placeholder="服务商" :disabled="invoiceForm.enterInfo.disabled">
            <el-option v-for="(item, index) in invoiceForm.enterInfo.list" :key="index" :label="item.EnterpriseFullName" :value="item.EnterpriseFullName">
            </el-option>
          </el-select>
        </el-form-item> -->
        <!-- 发票抬头 -->
        <el-form-item label="发票抬头" prop="invoice.value" label-width="100px">
          <el-select v-model="invoiceForm.invoice.value" clearable placeholder="发票抬头"
            :disabled="invoiceForm.invoice.disabled">
            <el-option v-for="(item, index) in invoiceForm.invoice.list" :key="index" :label="item.InvoiceTitleName"
              :value="item.InvoiceTitleName">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 发票类型 -->
        <!-- <el-form-item label="发票类型" prop="invoiceType.value" label-width="100px">
          <el-select v-model="invoiceForm.invoiceType.value" clearable placeholder="发票类型" :disabled="invoiceForm.invoiceType.disabled">
            <el-option v-for="(item, index) in invoiceForm.invoiceType.list" :key="index" :label="item.Name" :value="item.Code">
            </el-option>
          </el-select>
        </el-form-item> -->
        <!-- 专票普票 -->
        <el-form-item label="专票普票" prop="invoiceKind.value" label-width="100px">
          <el-input placeholder="请选择专票普票" v-model="invoiceKindValue" clearable @clear="cancleSelectKind"
            :disabled="invoiceForm.invoiceKind.disabled" @focus="flag.invoiceKindDialog = true" style="width: 380px;">
            <i slot="suffix" class="el-input__icon el-icon-arrow-down"></i>
          </el-input>
          <!-- <el-select v-model="invoiceForm.invoiceKind.value" clearable placeholder="专票普票"
            :disabled="invoiceForm.invoiceKind.disabled">
            <el-option v-for="(item, index) in invoiceForm.invoiceKind.list" :key="index" :label="item.Name"
              :value="item.Code">
            </el-option>
          </el-select> -->
        </el-form-item>
        <!-- 支付方式 -->
        <el-form-item label="支付方式" prop="payType.value" label-width="100px">
          <el-select v-model="invoiceForm.payType.value" clearable placeholder="支付方式"
            :disabled="invoiceForm.payType.disabled">
            <el-option v-for="(item, index) in invoiceForm.payType.list" :key="index" :label="item.Name"
              :value="item.Code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开票备注" prop="KPRemark" label-width="100px" style="width: 65%">
          <el-input type="textarea" :rows="2" placeholder="请输入备注" v-model="invoiceForm.KPRemark"
            :disabled="KPRemarkDisabled" maxlength="200" show-word-limit>
          </el-input>
        </el-form-item>
      </el-form>
      <!-- 表格信息 -->
      <el-table v-show="flag.submit" :data="tableData" v-loading="flag.dialogLoading"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }">
        <el-table-column fixed align="center" prop="index" label="序号" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="WaybillNumber" label="运单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="CarriageBillID" label="客户单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceType" label="发票类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskType" label="业务类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Shipper_name" label="货主单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverName" :label="getWaybillType.sceneId == 7 ? '操作员姓名' : '司机姓名'"
          width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Phone" :label="getWaybillType.sceneId == 7 ? '操作员手机号码' : '司机手机号码'
          " width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverIDCard" :label="getWaybillType.sceneId == 7 ? '操作员身份证号码' : '司机身份证号码'
          " width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row,scope.row.DriverUserID)">
              {{ scope.row.DriverIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="PayName" label="收款人姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Recy_payment_idcard" label="收款人手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayeeIDCard" label="收款人身份证号码" width="200">
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row,scope.row.PayeeUserID)">
              {{ scope.row.PayeeIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="LeaderName" label="车队长姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LeaderPhone" label="车队长手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LeaderIDCard" label="车队长身份证号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row,scope.row.LeaderUserID)">
              {{ scope.row.LeaderIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="LicensePlate" label="工程设备名称" width="200" v-if="getWaybillType.sceneId == 7">
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openVehicleInfo(scope.row)">
              {{ scope.row.LicensePlate }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="LicensePlate" label="车牌" width="200" v-if="getWaybillType.sceneId != 7">
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openVehicleInfo(scope.row)">
              {{ scope.row.LicensePlate }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Net_weight" label="装货净重(吨)" width="200" show-overflow-tooltip
          v-if="getWaybillType.sceneId != 7">
        </el-table-column>
        <el-table-column align="center" prop="Product_name" label="货品名称" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Product_unit" label="货品单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Loading_time" label="装货时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Submission_time" label="签收时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Start_addrees" label="起始地" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)">
              {{ scope.row.Start_addrees }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Arrivals" label="到达地" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)">
              {{ scope.row.Arrivals }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Number_allocated_drivers" :label="getWaybillType.sceneId == 7 ? '操作员装货数量' : '司机装货数量'
          " width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Driver_transportation_price" :label="getWaybillType.sceneId == 7
          ? '操作员运输单价（人民币）'
          : '司机运输单价（人民币）'
          " width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.Driver_transportation_price | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="amount_count" :label="getWaybillType.sceneId == 7 ? '工单费' : '运费'"
          width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.amount_count | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Taxes" label="服务费/税费" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.Taxes | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TotalAmount" :label="getWaybillType.sceneId == 7 ? '工单总金额' : '运单总金额'"
          width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.TotalAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Entrusted_Collection_Amount" label="委托代开运费" width="200"
          v-if="$entrustSceneIds.includes(Number(getWaybillType.sceneId))">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.Entrusted_Collection_Amount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="EntrustedTaxes" label="委托代开服务费/税费" width="200"
          v-if="$entrustSceneIds.includes(Number(getWaybillType.sceneId))">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.EntrustedTaxes | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="EntrustedTotalAmount" label="委托代开运单总金额" width="200"
          v-if="$entrustSceneIds.includes(Number(getWaybillType.sceneId))">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.EntrustedTotalAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Unloading_net_weight" label="卸货净重(吨)" width="200" show-overflow-tooltip
          v-if="getWaybillType.sceneId != 7">
        </el-table-column>
        <el-table-column align="center" prop="RiskPath" label="风控路径" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="" label="支付方式" width="200" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column align="center" prop="" label="开票金额" width="200" show-overflow-tooltip></el-table-column> -->
        <el-table-column align="center" prop="SourceBillId" label="溯源运单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            align="center"
            prop="PayeeRemark"
            label="收款人备注"
            width="200"
            show-overflow-tooltip
          ></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background class="pagination" v-show="flag.submit" @current-change="handleCurrentChange"
        :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
      <!-- 右下角按钮组 -->
      <div class="funcs">
        <div v-show="!flag.submit">
          <el-button type="primary" size="mini" @click="goStepOne">上一页</el-button>
          <el-button type="primary" size="mini" @click="submit('invoiceForm')">提交申请</el-button>
        </div>
        <div v-show="flag.submit">
          <el-button type="primary" size="mini" @click="flag.submit = false">上一步</el-button>
          <el-button type="primary" size="mini" :loading="flag.settlementing" @click="goStepThree">付款申请</el-button>
        </div>
      </div>
      <!-- 地图组件dialog -->
      <el-dialog width="800px" :visible.sync="flag.showMap" append-to-body>
        <!-- 地图组件 -->
        <TXmap style="height:600px;" v-if="flag.showMap" :list="mapList" ref="map"></TXmap>
      </el-dialog>
      <!-- 车辆信息dialog -->
      <el-dialog width="1300px" :visible.sync="flag.showVehicleInfo" append-to-body>
        <VehicleInfo ref="vehicleInfo" v-if="flag.showVehicleInfo" :vehicleInfo="vehicleInfo"></VehicleInfo>
      </el-dialog>
      <!-- 司机信息组件 -->
      <el-dialog width="1300px" :visible.sync="flag.showDriverInfo" append-to-body>
        <DriverInfo :driverInfo="driverInfo" />
      </el-dialog>
      <!-- 发票类型选择 -->
      <el-dialog width="550px" title="选择开票类型" :visible.sync="flag.invoiceKindDialog" append-to-body
        class="invoiceKindDialog">
        <el-divider></el-divider>
        <div style="font-size: 14px;font-weight: bold;color: #333333;margin-bottom:8px;">
          运费发票
        </div>
        <div style="display: flex;align-items: center;flex-wrap: wrap;">
          <el-radio v-model="invoiceKindVal1" v-for="(item, index) in invoiceForm.invoiceKind.list" :key="index"
            :label="item" :disabled="item.IsDisable" style="margin-bottom: 10px;">{{ item.Name }}</el-radio>
        </div>
        <div style="font-size: 14px;font-weight: bold;color: #333333;margin-top:24px;margin-bottom:8px">
          技术服务费发票
        </div>
        <div style="display: flex;align-items: center;flex-wrap: wrap;">
          <el-radio v-model="invoiceKindVal2" v-for="(item, index) in invoiceForm.invoiceKind.list1" :key="index"
            :label="item" :disabled="item.IsDisable" style="margin-bottom: 10px;">{{ item.Name }}</el-radio>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="flag.invoiceKindDialog = false">取 消</el-button>
          <el-button type="primary" @click="submitSelectKind">保 存</el-button>
        </span>
      </el-dialog>
      <el-dialog :visible.sync="flag.errorDriveTableDialog" title="异常信息" center append-to-body>
        <div style="position: relative;margin-bottom: 10px;height: 40px;line-height: 40px;">
          <div style="position: absolute;top: 0;left: 0;">
            <el-button type="primary" @click="downLoadErrorData">下载表格</el-button>
            <el-button type="primary" @click="driverTaxRegCheckAdd" style="margin-left: 10px;">提交平台审核</el-button>
          </div>
          <div style="text-align: center; color: red;font-size: 16px;">{{ checkTaxRegData.ErrorsTitle }}</div>
        </div>
        <div style="height:60vh;overflow-y:scroll">
          <el-table :data="errorDriveTable" :header-cell-style="{ color: '#666', background: '#f0f0f0' }">
            <el-table-column type="index" align="center" label="序号" width="80"></el-table-column>
            <el-table-column align="center" prop="DriverName" label="司机姓名" width="200" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="DriverPhone" label="手机号" width="200" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="ErrorMsg" label="异常信息" show-overflow-tooltip>
              <template slot-scope="scope">
                <span style="color: red;">{{ scope.row.ErrorMsg }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  getDataDict,
  getMapPath,
  getCarInfo,
  getDriverDetails,
  carInfoByNum,
} from "@/api/common/common";
import VehicleInfo from "@/components/businessCmpt/vehicleInfo";
import {
  getArea,
  getAreaChildren,
  getProviderInfo,
  saveInvoceInfo,
  billFeeCount,
  consignSixElement,
  getDictInfoListForNine,
  GetTicketType,
  CheckTaxReg,
  CheckTaxRegDownd,
  DriverTaxRegCheckAdd
} from "@/api/waybill/waybillManage/index";
import { mapMutations, mapGetters } from "vuex";
import Step from "@/components/businessCmpt/step";
import TXmap from "@/components/commonCmpt/TXmap";
import DriverInfo from "@/components/businessCmpt/driverInfo";
import { GetHtwlFacilitatorIDAsync } from "@/api/contractLogistics/contractWaybill.js";
import { _getUserId } from "@/utils/storage";
export default {
  props: {
    waybillList: {
      type: Array,
    },
    ImportBatchID: {
      type: String,
    },
  },
  data() {
    return {
      pagination: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      flag: {
        submit: false, //是否点过提交申请按钮
        settlement: false, //是否显示结算信息
        showVehicleInfo: false, //显示车辆信息
        showDriverInfo: false, //显示司机信息
        showMap: false, //显示地图
        dialogLoading: false, //是否正在加载地图
        settlementing: false, //结算申请中
        dialogLoading: false, //dialog弹窗是否是loading状态
        invoiceKindDialog: false, // 选择发票弹窗
        errorDriveTableDialog: false
      },
      errorDriveTable: [],
      invoiceKindValue: "",
      invoiceKindVal1: {},
      invoiceKindVal2: {},
      invoiceForm: {
        address: {
          area: "",
          value: "label",
          disabled: false,
          lazy: true,
          lazyLoad(node, resolve) {
            const { level } = node;
            if (level == 0) {
              getArea().then((res) => {
                res.result[0].forEach((item) => {
                  item.label = item.fullname;
                  item.leaf = level >= 2;
                });
                resolve(res.result[0]);
              });
            } else {
              //如果是香港和澳门特别行政区就特别处理
              if (node.data.id == "810000" || node.data.id == "820000") {
                getAreaChildren({ id: node.data.id }).then((res) => {
                  res.result[0].forEach((item) => {
                    item.label = item.fullname;
                    item.leaf = true;
                  });
                  resolve(res.result[0]);
                });
              } else {
                getAreaChildren({ id: node.data.id }).then((res) => {
                  res.result[0].forEach((item) => {
                    item.label = item.fullname;
                    item.leaf = level >= 2;
                  });
                  resolve(res.result[0]);
                });
              }
            }
          },
        }, //选择地区
        supplier: {
          value: "",
          disabled: false,
          list: [],
        }, //已签约服务商
        enterInfo: {
          value: "",
          disabled: false,
          list: [],
        }, //服务商
        invoice: {
          value: "",
          disabled: false,
          list: [],
        }, //发票抬头
        invoiceType: {
          value: "",
          disabled: false,
          list: [],
        }, //发票类型
        payType: {
          value: "",
          disabled: false,
          list: [],
        }, //支付方式
        invoiceKind: {
          value: "",
          disabled: false,
          list: [],
          list1: [],
        }, //专票普票
        makeInvoiceMoney: {
          value: "",
          disabled: false,
          list: [],
        }, //开票金额
        KPRemark: "", // 开票备注
        invoicingType: null, // 票制
      },
      invoiceRule: {
        "address.area": [
          { required: true, message: "请选择选择地区", trigger: "change" },
        ],
        "invoice.value": [
          { required: true, message: "请选择发票抬头", trigger: "change" },
        ],
        "invoiceType.value": [
          { required: true, message: "请选择发票类型", trigger: "change" },
        ],
        "payType.value": [
          { required: true, message: "请选择支付方式", trigger: "change" },
        ],
        // "makeInvoiceMoney.value": [
        //   { required: true, message: "请选择开票金额", trigger: "change" },
        // ],
      },
      total: {
        totalFreight: "", //总运费
        totalTaxes: "", //总税费
        totalMoney: "", //总金额
        settlementDate: "", //结算申请时间
        bossEnterpriseFullName: "", //货主单位
        bossEnterpriseCreditCode: "", //货主税号
        bossEnterpriseAddress: "", //货主地址
        bossBankerName: "", //开户行
        bossBankerNumber: "", //货主账号
        bossLegalPersonlPhone: "", //货主手机号
      },
      vehicleInfo: {}, //车辆信息
      driverInfo: {}, //司机信息
      mapList: [], //轨迹列表
      KPRemarkDisabled: false,
      checkTaxRegData: {}
    };
  },
  methods: {
    ...mapMutations(["setInvoiceTaskID", "setWaybillType"]),
    // 异常信息提交平台审核
    driverTaxRegCheckAdd() {
      this.$confirm('确定要提交平台审核吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let providerId = null; //已签约服务商id
        this.invoiceForm.supplier.list.forEach((el) => {
          if (el.name == this.invoiceForm.supplier.value) {
            providerId = el.value;
          }
        });
        const loading = this.$loading({
          lock: true,
          text: '提交中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let arr = []
        this.errorDriveTable.map(item => {
          arr.push({
            driverUserID: item.DriverUserID,
            reson: item.ErrorMsg,
            ownerUserID: parseInt(_getUserId()) / 5,
            supplierUserID: providerId
          })
        })
        DriverTaxRegCheckAdd(arr).then(res => {
          this.$message.success('提交成功');
          this.flag.errorDriveTableDialog = false
          this.$emit("stepChange", 1);
        }).finally(() => {
          loading.close()
        })
      })
    },
    // 取消选择专普票
    cancleSelectKind() {
      this.flag.invoiceKindDialog = false;
      this.invoiceKindValue = "";
      this.invoiceKindVal1 = {};
      this.invoiceKindVal2 = {};
    },
    downLoadErrorData() {
      CheckTaxRegDownd({
        isOk: this.checkTaxRegData.IsOk,
        errors: this.checkTaxRegData.Errors,
        ErrorsTitle: this.checkTaxRegData.ErrorsTitle
      }).then(res => {
        window.location.href = res.data
      })
    },
    // 保存选择专普票
    submitSelectKind() {
      // if (this.invoiceForm.invoicingType == 1) {
      //   this.invoiceKindValue = this.invoiceKindVal1.Name
      // } else {
      //   if (!this.invoiceKindVal1.Name) {
      //     this.$message.warning('请选择运费发票！')
      //     return
      //   }
      //   if (!this.invoiceKindVal2.Name) {
      //     this.$message.warning('请选择技术服务费发票！')
      //     return
      //   }
      //   this.invoiceKindValue = `运费-${this.invoiceKindVal1.Name} 技术服务费-${this.invoiceKindVal2.Name}`
      // }
      if (!this.invoiceKindVal1.Name) {
        this.$message.warning("请选择运费发票！");
        return;
      }
      if (!this.invoiceKindVal2.Name) {
        this.$message.warning("请选择技术服务费发票！");
        return;
      }
      this.invoiceKindValue = `运费-${this.invoiceKindVal1.Name} 技术服务费-${this.invoiceKindVal2.Name}`;
      this.flag.invoiceKindDialog = false;
    },
    //去第一页
    goStepOne() {
      this.setWaybillType({
        taskType: this.tableData[0].TaskType,
        taskTypeId: this.tableData[0].TaskTypeID,
        rateType: this.tableData[0].InvoiceType,
        sceneType: this.tableData[0].TaskSceneName,
        sceneId: this.tableData[0].TaskSceneTypeID,
        waybillList: this.waybillList,
      });
      this.$emit("stepChange", 1);
    },
    //去第三页
    goStepThree() {
      this.flag.settlementing = true;
      //处理地址格式给后端
      let address = "未选择地区";
      // this.invoiceForm.address.area.forEach((item, index) => {
      //   if (index == 0) {
      //     address = item
      //     return
      //   }
      //   address = address + '-' + item
      // })
      let providerId = null; //已签约服务商id
      this.invoiceForm.supplier.list.forEach((el) => {
        if (el.name == this.invoiceForm.supplier.value) {
          providerId = el.value;
        }
      });
      let params = {
        Address: address, //选择地区
        Contracted_provider: this.invoiceForm.supplier.value, //已签约服务商
        ProviderId: providerId, //已签约服务商id
        Unsigned_provider: this.invoiceForm.enterInfo.value, //未签约服务商
        Invoice_title: this.invoiceForm.invoice.value, //发票抬头
        Invoice_type: this.invoiceForm.invoiceType.value, //发票类型
        InvoiceKind: this.invoiceKindVal1.Code, //运费专票普票
        InvoiceKindTec: this.invoiceKindVal2.Code, // 技术服务费专票普票
        PayMethod: this.invoiceForm.payType.value, //支付方式
        InvoiceAmoutType: 1, //开票金额
        KPRemark: this.invoiceForm.KPRemark, // 开票备注
        billId: this.waybillList.map((item) => item.WaybillNumber), //运单号
        TransFee: this.totalFreight, //总运输费
        Taxes: this.totalTaxes, //总税费
        ServiCharge: "", //服务费
        InvoicDt: this.total.settlementDate, //结算申请时间
        OldYD: this.waybillList.map((item) => item.OldYD), //委托代开运单号
      };
      // 如果有开票单号就把开票单号传过去  后端不做添加操作 做修改操作
      if (this.getInvoiceTaskID) {
        params.InvoiceTaskID = this.getInvoiceTaskID;
      }
      saveInvoceInfo({
        Json: JSON.stringify(params),
        taskSceneTypeID: this.getWaybillType.sceneId,
      })
        .then((res) => {
          this.setInvoiceTaskID(res.AddInvoicInfo);
          this.$emit("stepChange", 3);
        })
        .finally(() => {
          this.flag.settlementing = false;
        });
    },
    openMap(item) {
      this.flag.dialogLoading = true;
      let params = {
        wayBillId: item.WaybillNumber,
        searchWord: item.LicensePlate,
        stDate: item.Loading_time,
        edDate: item.Submission_time,
        coordType: 2,
      };
      getMapPath({ json: JSON.stringify(params) })
        .then((res) => {
          this.mapList = res.Track;
          this.flag.showMap = true;
        })
        .finally(() => {
          this.flag.dialogLoading = false;
        });
    },
    //打开车辆信息
    openVehicleInfo(item) {
      this.flag.dialogLoading = true;
      let params = {
        CarNumber: item.LicensePlate ? item.LicensePlate : item.CarNumber,
      };
      let data = {};
      getCarInfo(params)
        .then((res) => {
          data = Object.assign(
            {},
            res.data.vehicle_baseInfo,
            res.data.vehicle_confirmInfo
          );
          this.vehicleInfo = data;
          this.flag.showVehicleInfo = true;
        })
        .finally(() => {
          this.flag.dialogLoading = false;
        });
    },
    //查看司机信息
    openDerverInfo(item,UserID) {
      this.flag.dialogLoading = true;
      let data = {};
      getDriverDetails({ UserID: UserID, AscriptionUserID: item.UserID })
        .then((res) => {
          data = Object.assign(
            {},
            res.data.driver_baseInfo,
            res.data.driver_confirmInfo
          );
          this.driverInfo = data;
          this.flag.showDriverInfo = true;
        })
        .finally(() => {
          this.flag.dialogLoading = false;
        });
    },
    //提交申请
    submit(formName) {
      //判断有没有选择服务商
      if (
        !this.invoiceForm.enterInfo.value &&
        !this.invoiceForm.supplier.value
      ) {
        this.$message.warning("请选择服务商");
        return;
      }
      if (!this.invoiceKindValue) {
        this.$message.warning("请选择专票普票！");
        return;
      }
      //提交
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.flag.settlement = true;
          //获取发票合计信息
          this.billFeeCount();
        }
      });

      // //判断有没有选择服务商
      // if (!this.invoiceForm.enterInfo.value && !this.invoiceForm.supplier.value) {
      //   this.$message.warning('请选择服务商')
      //   return
      // }
      // //判断服务商是否有电子账户和电子签名
      // let supplierId = null //选中的服务商id
      // for (let i = 0; i < this.invoiceForm.supplier.list; i++) {
      //   if (this.invoiceForm.supplier.value == this.invoiceForm.supplier.list[i].name) {
      //     supplierId = this.invoiceForm.supplier.list[i].value
      //     break
      //   }
      // }
      // let params = {
      //   providerUserID: supplierId
      // }
      // getUserApprove(params).then(res => {
      //   //提交
      //   this.$refs[formName].validate((valid) => {
      //     if (valid) {
      //       this.flag.settlement = true
      //       this.flag.submit = true
      //       //获取发票合计信息
      //       this.billFeeCount()
      //     }
      //   })
      // }).catch(() => {
      //   //没有电子账户和电子签名
      //   this.$message.warning('服务商没有电子账户或电子签名')
      // })
    },
    //分页回调
    handleCurrentChange(e) {
      this.pagination.page = e;
    },
    //查询开票信息
    getProviderInfo() {
      let params = {
        TaskSceneTypeID: this.waybillList[0].TaskSceneTypeID,
        importBatchId: this.ImportBatchID,
        invoiceTaskId: this.getInvoiceTaskID,
      };
      getProviderInfo(params).then(async (res) => {
        this.invoiceForm.invoicingType = res.invoicingType;
        //如果已签约服务商有值
        if (res.supplier && res.supplier.length != 0) {
          // 如果是合同物流, 只能选择货主指定的服务商
          let supplerIDoBJ = await GetHtwlFacilitatorIDAsync(
            this.ImportBatchID
          );
          let { data } = supplerIDoBJ;

          res.supplier.forEach((item) => {
            //已签约服务商
            this.invoiceForm.supplier.list.push({
              name: item.EnterpriseFullName,
              value: item.UserID,
              InvoiceType: item.InvoiceType,
              Flag: item.Flag,
              Disabled: !data ? false : data == item.UserID ? false : true,
            });
          });
          let arr = res.supplier.map((item) => {
            return item;
          });
          res.enterInfo.forEach((item, index) => {
            arr.forEach((el) => {
              //找到重复的是哪个对象再删除
              if (item.UserID == el.UserID) {
                res.enterInfo.splice(index, 1);
              }
            });
          });
        }
        // this.invoiceForm.makeInvoiceMoney.list = res.dictInfo; //开票金额
        this.invoiceForm.enterInfo.list = res.enterInfo; //服务商
        this.invoiceForm.KPRemark = res.KPRemark; // 开票备注
        this.invoiceForm.invoice.list = res.invoice; //发票抬头
        //默认选中这个第一个发票抬头
        this.invoiceForm.invoice.value = res.invoice[0].InvoiceTitleName;
        // 默认选中开票金额
        // if (res.dictInfo && res.dictInfo.length) {
        //   res.dictInfo.map((it) => {
        //     if (it.Code == 3) {
        //       this.invoiceForm.makeInvoiceMoney.value = it.Code;
        //     }
        //   });
        // }
      });
    },
    //获取支付方式，发票类型
    getOtherList() {
      //支付方式
      getDictInfoListForNine({
        TaskSceneTypeID: this.getWaybillType.sceneId,
      }).then((res) => {
        this.invoiceForm.payType.list = res.payDacit;
        //默认选中余额支付方式
        res.payDacit.forEach((item) => {
          if (item.Code == 2) {
            this.invoiceForm.payType.value = item.Code;
          }
        });
      });
      //发票类型
      getDataDict({
        type: 20,
        code: this.$entrustSceneIds.includes(Number(this.getWaybillType.sceneId)) ? "02" : "01",
      }).then((res) => {
        this.invoiceForm.invoiceType.list = res.patterSetInfo;
        //默认选中第一个发票类型
        this.invoiceForm.invoiceType.value = res.patterSetInfo[0].Code;
      });
      //专票普票
      // getDataDict({ type: 67 }).then(res => {
      //   this.invoiceForm.invoiceKind.list = res.patterSetInfo
      // })
      GetTicketType({ TaskSceneTypeID: this.getWaybillType.sceneId }).then(
        (res) => {
          this.invoiceForm.invoiceKind.list = res.data.Carriage;
          this.invoiceForm.invoiceKind.list1 = res.data.CarriageTec;
        }
      );
    },
    //计算运单总税费和总运费
    billFeeCount() {
      let totalAmount = this.waybillList.map((item) => {
        return this.$entrustSceneIds.includes(Number(this.getWaybillType.sceneId))
          ? item.Entrusted_Collection_Amount
          : item.amount_count;
      });

      let address = "未选择地区";
      // this.invoiceForm.address.area.forEach((item, index) => {
      //   if (index == 0) {
      //     address = item
      //     return
      //   }
      //   address = address + '-' + item
      // })
      let providerId = null; //已签约服务商id
      this.invoiceForm.supplier.list.forEach((el) => {
        if (el.name == this.invoiceForm.supplier.value) {
          providerId = el.value;
        }
      });
      let params = {
        Address: address, //选择地区
        Contracted_provider: this.invoiceForm.supplier.value, //已签约服务商
        ProviderId: providerId, //已签约服务商id
        Unsigned_provider: this.invoiceForm.enterInfo.value, //未签约服务商
        Invoice_title: this.invoiceForm.invoice.value, //发票抬头
        Invoice_type: this.invoiceForm.invoiceType.value, //发票类型
        InvoiceKind: this.invoiceKindVal1.Code, //运费专票普票
        InvoiceKindTec: this.invoiceKindVal2.Code, // 技术服务费专票普票
        PayMethod: this.invoiceForm.payType.value, //支付方式
        InvoiceAmoutType: 1, //开票金额
        KPRemark: this.invoiceForm.KPRemark, // 开票备注
        billId: this.waybillList.map((item) => item.WaybillNumber), //运单号
        TransFee: this.totalFreight, //总运输费
        Taxes: this.totalTaxes, //总税费
        ServiCharge: "", //服务费
        InvoicDt: this.total.settlementDate, //结算申请时间
        OldYD: this.waybillList.map((item) => item.OldYD), //委托代开运单号
      };
      // 如果有开票单号就把开票单号传过去  后端不做添加操作 做修改操作
      if (this.getInvoiceTaskID) {
        params.InvoiceTaskID = this.getInvoiceTaskID;
      }

      CheckTaxReg({
        Json: JSON.stringify(params),
        taskSceneTypeID: this.getWaybillType.sceneId,
      }).then(res => {
        this.checkTaxRegData = res.data
        if (res.data.IsOk) {
          this.flag.submit = true;
          billFeeCount({
            Json: JSON.stringify({
              BillsId: this.waybillList,
              TotalAmount: totalAmount,
              invoiceType: this.invoiceForm.invoiceType.value,
              ProviderName: this.invoiceForm.supplier.value, //已签约服务商
              ProviderId: providerId, //已签约服务商id
              UnsignProviderName: this.invoiceForm.enterInfo.value, //未签约服务商
              InvoiceKind: this.invoiceKindVal1.Code,
              TaskSceneTypeID: this.getWaybillType.sceneId
            })
          }).then((res) => {
            this.total.totalMoney = res.BillFeeCount; //总金额
            this.total.totalTaxes = res.BillFeeCount1; //总税费
            this.total.totalFreight = res.BillFeeCount0; //总运费
            this.total.settlementDate = res.TaskDateTime; //结算申请时间
            this.total.bossEnterpriseFullName = res.Consign.InvoiceTitleName;
            this.total.bossEnterpriseCreditCode =
              res.Consign.InvoiceTitleCreditCode;
            this.total.bossEnterpriseAddress = res.Consign.InvoiceTitleAddress;
            this.total.bossBankerName = res.Consign.InvoiceTitleBankerName;
            this.total.bossBankerNumber = res.Consign.InvoiceTitleBankerNumber;
            this.total.bossLegalPersonlPhone = res.Consign.InvoiceTitlePhone;
          });
        } else {
          this.flag.errorDriveTableDialog = true
          this.errorDriveTable = res.data.Errors
        }
      })
    },
    //设置结算信息
    setSettlementInfo() {
      //获取货主六要素
      consignSixElement().then((res) => {
        this.total.bossEnterpriseFullName = res.SixElement.InvoiceTitleName;
        this.total.bossEnterpriseCreditCode =
          res.SixElement.InvoiceTitleCreditCode;
        this.total.bossEnterpriseAddress = res.SixElement.InvoiceTitleAddress;
        this.total.bossBankerName = res.SixElement.InvoiceTitleBankerName;
        this.total.bossBankerNumber = res.SixElement.InvoiceTitleBankerNumber;
        this.total.bossLegalPersonlPhone = res.SixElement.InvoiceTitlePhone;
      });
    },
    //设置运费
    getAmountCount() {
      let num = 0;
      if (this.$entrustSceneIds.includes(Number(this.getWaybillType.sceneId))) {
        this.waybillList.forEach((item) => {
          num += Number(item.Entrusted_Collection_Amount);
        });
      } else {
        this.waybillList.forEach((item) => {
          num += Number(item.amount_count);
        });
      }
      this.total.totalFreight = num.toFixed(2);
    },
  },
  created() {
    //设置结算信息
    this.setSettlementInfo();
    //设置分页的总条数
    this.pagination.total = this.waybillList.length;
    //查询开票信息
    this.getProviderInfo();
    //获取发票类型，支付方式
    this.getOtherList();
    //设置运费
    this.getAmountCount();
  },
  computed: {
    ...mapGetters(["getInvoiceTaskID", "getWaybillType"]),
    tableData() {
      return this.waybillList
        .slice(
          this.pagination.pagesize * (this.pagination.page - 1),
          this.pagination.pagesize * this.pagination.page
        )
        .map((item, index) => {
          item.index = index + 1;
          return item;
        });
    },
    //专票普票
    invoiceKind() {
      let invoiceKind = "";
      this.invoiceForm.invoiceKind.list.forEach((item) => {
        if (item.Code == this.invoiceKindVal1.Code) {
          invoiceKind = item.Name;
        }
      });
      return invoiceKind;
    },
    invoiceKindTec() {
      let invoiceKindTec = "";
      this.invoiceForm.invoiceKind.list1.forEach((item) => {
        if (item.Code == this.invoiceKindVal2.Code) {
          invoiceKindTec = item.Name;
        }
      });
      return invoiceKindTec;
    },
    //开票金额
    // makeInvoiceMoney() {
    //   if (!this.invoiceForm.makeInvoiceMoney.value) return;
    //   let makeInvoiceMoney = this.invoiceForm.makeInvoiceMoney.list.filter(
    //     (item) => item.Code == this.invoiceForm.makeInvoiceMoney.value
    //   );
    //   return makeInvoiceMoney[0].Name;
    // },
    //支付类型
    payType() {
      if (!this.invoiceForm.payType.value) return;
      let payType = this.invoiceForm.payType.list.filter(
        (item) => item.Code == this.invoiceForm.payType.value
      );
      return payType[0].Name;
    },
  },
  watch: {
    "flag.submit"(val) {
      if (val) {
        this.invoiceForm.address.disabled = true;
        this.invoiceForm.supplier.disabled = true;
        this.invoiceForm.enterInfo.disabled = true;
        this.invoiceForm.invoice.disabled = true;
        this.invoiceForm.invoiceType.disabled = true;
        this.invoiceForm.invoiceKind.disabled = true;
        this.invoiceForm.payType.disabled = true;
        this.invoiceForm.makeInvoiceMoney.disabled = true;
        this.KPRemarkDisabled = true;
      } else {
        this.invoiceForm.address.disabled = false;
        this.invoiceForm.supplier.disabled = false;
        this.invoiceForm.enterInfo.disabled = false;
        this.invoiceForm.invoice.disabled = false;
        this.invoiceForm.invoiceType.disabled = false;
        this.invoiceForm.invoiceKind.disabled = false;
        this.invoiceForm.payType.disabled = false;
        this.invoiceForm.makeInvoiceMoney.disabled = false;
        this.KPRemarkDisabled = false;
      }
    },
    "invoiceForm.supplier.value"(val) {
      if (val !== "") {
        this.invoiceForm.enterInfo.value = "";
      }
    },
    "invoiceForm.enterInfo.value"(val) {
      if (val !== "") {
        this.invoiceForm.supplier.value = "";
      }
    },
    "flag.showMap"(val) {
      if (!val) {
        this.mapList = [];
      }
    },
  },
  components: {
    Step,
    TXmap,
    VehicleInfo,
    DriverInfo,
  },
};
</script>

<style lang="scss">
@import "../../../../../../assets/style/variable.scss";

.facilityBox {
  .settleBox {
    margin-bottom: 10px;
    padding: 10px 16px;
    background-color: #f0f0f0;
    color: #666;
  }

  .step {
    margin: 0 auto 16px;
  }

  .sumArea {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    p {
      width: 150px;
      margin-right: 10px;
      font-size: 20px;
      font-weight: 700;
    }

    .total {
      background: $primary;
      display: flex;
      flex-wrap: wrap;
      color: white;
      padding: 10px;
      border-radius: 5px;

      .item {
        width: 25%;
      }
    }
  }

  .bodyArea {
    .title {
      color: #2081ff;
      position: relative;
      text-indent: 15px;
      font-size: 14px;
      border-bottom: 1px solid $borderGray;
      padding: 8px 0;
      margin-bottom: 20px;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 3px;
        height: 14px;
        border-radius: 5px;
        background: $primary;
      }
    }

    .invoiceForm {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
    }

    .pagination {
      text-align: left;
      margin-top: 10px;
    }

    .funcs {
      text-align: center;
    }
  }
}

.invoiceKindDialog {
  .el-dialog__header {
    padding-bottom: 0px;
  }

  .el-dialog__body {
    padding: 0px 20px;

    .el-divider--horizontal {
      margin: 16px 0px;
    }
  }

  .el-dialog__footer {
    padding-top: 16px;
    text-align: center;
  }
}
</style>
